import API from '..'

export const AuthAPI = {
  signin: () => {
    return API.get('/fanfou/signin')
  },
  signout: () => {
    return API.get('/fanfou/signout')
  },
  callback: (oauthToken: string | null) => {
    return API.get('/fanfou/callback', { params: { oauth_token: oauthToken } })
  },
  verifyAuth: () => {
    return API.get('/fanfou/verify_auth')
  },
  test: () => {
    return API.get('/fanfou/count')
  },
}
