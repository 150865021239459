import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Grow,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React, { useState } from 'react'
import MeshiyaImage from './../../assets/images/meshiya_c.jpg'
import SushiImage from './../../assets/images/sushi.jpg'
import Apps from './../../components/AppRegistry'
import DelayedLink from './../../components/DelayedLink'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(3),
  },
  card: {
    maxWidth: 346,
  },
  media: {
    height: 160,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  buildingTitle: {
    color: grey[400],
  },
  buildingDescription: {
    color: grey[500],
  },
}))

const Home: React.FC = () => {
  const classes = useStyles()
  const [showCards, setShowCards] = useState(true)

  return (
    <Container className={classes.container} maxWidth='xl'>
      <Grid container={true} spacing={3}>
        {Apps.filter(app => !app.hidden).map((app, index) => (
          <Grid key={index} item={true} xs={6} md={4} lg={3} xl={2}>
            <Grow in={showCards}>
              <Card
                className={classes.card}
                onClick={() => {
                  setShowCards(false)
                }}>
                <DelayedLink to={app.path.toString()} className={classes.link}>
                  <CardActionArea>
                    <CardMedia className={classes.media} image={app.image || MeshiyaImage} title={app.name} />

                    <CardContent>
                      <Typography gutterBottom={true} variant='h6' component='h6'>
                        {app.name}
                      </Typography>

                      <Typography variant='body2' color='textSecondary' component='p'>
                        {app.description || null}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </DelayedLink>
              </Card>
            </Grow>
          </Grid>
        ))}
        <Grid item={true} xs={6} md={4} lg={3} xl={2}>
          <Grow in={showCards}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia className={classes.media} image={SushiImage} title={'开发中...'} />
                <CardContent>
                  <Typography gutterBottom={true} className={classes.buildingTitle} variant='h6' component='h6'>
                    开发中...
                  </Typography>
                  <Typography variant='body2' className={classes.buildingDescription} component='p'>
                    敬请期待！
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
