import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Hidden,
  Link,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import { RootDispatch, RootState } from './../../ducks'
import CustomTable from './table'

interface ITabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  statusCard: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  cover: {
    width: 160,
  },
  statusContent: {
    flex: 1,
  },
  screenName: {
    marginBottom: theme.spacing(1),
  },
  appCredit: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: grey[500],
    fontSize: 9,
    maxWidth: 140,
  },
  colID: {
    width: 160,
    minWidth: 160,
    maxWidth: 160,
  },
  tabBlock: {
    marginBottom: theme.spacing(1),
  },
  tableBlock: {
    marginBottom: theme.spacing(3),
  },
  progress: {
    alignSelf: 'center',
    margin: theme.spacing(2),
  },
}))

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      <Box>{children}</Box>
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const Analyze: React.FC = (props?: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const followDiff = useSelector((state: RootState) => state.followDiff)
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch<RootDispatch>()

  const [tabIndex, setTabIndex] = useState(0)
  const [willUnmount, setWillUnmount] = useState(false)

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    setTabIndex(newValue)
  }

  function handleTabIndexChange(index: number) {
    setTabIndex(index)
  }

  const exparteFollowers = followDiff.data.exparte_followers
  const exparteFollowings = followDiff.data.exparte_followings

  useEffect(() => {
    dispatch.followDiff.getFollowDiff()
    return () => {
      setWillUnmount(true)
      dispatch.followDiff.clearFollowDiff()
    }
  }, [dispatch])

  return (
    <Container className={classes.container} maxWidth='lg'>
      <Slide in={!willUnmount} direction='down'>
        <Card className={classes.statusCard}>
          <Hidden only='xs'>
            <CardMedia className={classes.cover} image={props.app.image} />
          </Hidden>
          <CardContent className={classes.statusContent}>
            <Typography variant='h4' className={classes.screenName}>
              {user.screen_name}
            </Typography>
            <Typography>关注者：{user.followers_count ? user.followers_count.toString() : '0'}</Typography>
            <Typography>关注：{user.friends_count ? user.friends_count.toString() : '0'}</Typography>
          </CardContent>
          <CardContent className={classes.appCredit}>
            <div>
              特别感谢{' '}
              <Link target='_blank' rel='noopener' href='http://fanfou.com/stranger'>
                shawjia
              </Link>{' '}
              的技术指导（
              <Link target='_blank' rel='noopener' href='https://marcher.sinaapp.com/fanyou/'>
                饭友
              </Link>
              ）
            </div>
            <div>
              封面图作者{' '}
              <Link target='_blank' rel='noopener' href='https://dribbble.com/shots/133859-App-icon'>
                Mike | Creative Mints
              </Link>
            </div>
          </CardContent>
        </Card>
      </Slide>

      <Slide in={!willUnmount} direction='up'>
        <Paper className={classes.tabBlock}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            aria-label='tabs for exparte follow statuses'>
            <Tab label={`我没关注的人（${exparteFollowers.length}）`} {...a11yProps(0)} />
            <Tab label={`没关注我的人（${exparteFollowings.length}）`} {...a11yProps(1)} />
          </Tabs>
        </Paper>
      </Slide>

      <Slide in={!willUnmount} direction='up'>
        <Paper className={classes.tableBlock}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabIndex}
            onChangeIndex={handleTabIndexChange}>
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              <CustomTable list={exparteFollowers} loading={followDiff.loadingForItem} type='exparte_followers' />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction}>
              <CustomTable list={exparteFollowings} loading={followDiff.loadingForItem} type='exparte_followings' />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Slide>

      {followDiff.loadingForPage ? <CircularProgress className={classes.progress} /> : null}
    </Container>
  )
}

export default Analyze
