import API from '..'

export const FollowAPI = {
  followDiff: () => {
    return API.get('/fanfou/follow_diff')
  },
  follow: (id: string) => {
    return API.get('/fanfou/follow', { params: { id } })
  },
  unfollow: (id: string) => {
    return API.get('/fanfou/unfollow', { params: { id } })
  },
}
