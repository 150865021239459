import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import { hot } from 'react-hot-loader'
import UpdateAlert from './components/UpdateAlert'
import Routes from './routes'
import { lightTheme } from './theme'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <UpdateAlert />
      <Routes />
    </ThemeProvider>
  )
}

export default hot(module)(App)
