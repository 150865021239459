import React, { ComponentClass, ComponentType } from 'react'
import TopNav from './index'

function withTopNav(WrappedComponent: ComponentType): ComponentClass {
  return class WithTopNav extends React.Component {
    public render() {
      const { ...props } = this.props
      return (
        <>
          <TopNav {...props} />
          <WrappedComponent {...props} />
        </>
      )
    }
  }
}

export default withTopNav
