import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import * as models from './models'

export { models }
export type models = typeof models
export type RootState = RematchRootState<typeof models>
export type RootDispatch = RematchDispatch<typeof models>

export const store = init({
  models,
})
