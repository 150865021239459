import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Fab,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { RematchDispatch, RematchRootState } from '@rematch/core'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AuthAPI } from '../../api'
import meshiya from './../../assets/images/meshiya_c.jpg'
import { models } from './../../ducks'
import { clearSession } from './../../utils/session'
import Quote from './quote'

const mapState = (state: RematchRootState<models>) => ({
  user: state.user,
})

const mapDispatch = (dispatch: RematchDispatch<models>) => ({
  verifyCredentials: dispatch.user.verifyCredentials,
})

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 268px)',
    },
  },
  expand: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(12),
  },
  actions: {
    flexDirection: 'column',
  },
  loginButton: {
    marginBottom: theme.spacing(2),
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.15), 0px 6px 10px 0px rgba(0,0,0,0.09), 0px 1px 18px 0px rgba(0,0,0,0.07)',
  },
  fanfouIcon: {
    width: 30,
    height: 30,
    marginLeft: -4,
    marginRight: theme.spacing(1),
  },
  loadingIcon: {
    color: '#ffffff',
    marginLeft: -4,
    marginRight: theme.spacing(1),
  },
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(22),
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  quoteBox: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  copyright: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: grey[500],
  },
}))

function FanfouIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zM6.397 10.648c0.426-0.751 0.806-1.531 1.139-2.341h1.848c-0.186 0.548-0.447 1.207-0.785 1.974 0.27 0.084 0.553 0.211 0.848 0.38 0.287-0.726 0.591-1.51 0.911-2.354v-0.861h-2.493c0.215-0.586 0.405-1.19 0.57-1.81l-0.924-0.215c-0.363 1.51-0.932 2.962-1.709 4.354 0.217 0.256 0.414 0.542 0.581 0.847l0.013 0.027zM8.232 15.204v-5.366h-0.911v5.138c0 0.456-0.152 0.835-0.456 1.139l0.582 0.81c0.599-0.464 1.515-1.114 2.746-1.949-0.093-0.312-0.16-0.645-0.203-1-0.675 0.498-1.261 0.907-1.759 1.228zM10.953 12.141c0 2.143-0.591 3.797-1.772 4.961 0.228 0.236 0.447 0.506 0.658 0.81 1.35-1.392 2.025-3.32 2.025-5.784v-1.86h0.74c0.262 1.844 0.875 3.388 1.842 4.632-0.861 0.81-1.983 1.519-3.367 2.126 0.278 0.312 0.498 0.599 0.658 0.861 1.392-0.734 2.497-1.498 3.316-2.291 0.839 0.873 1.747 1.59 2.721 2.152 0.253-0.321 0.51-0.624 0.772-0.911-1.118-0.515-2.065-1.164-2.841-1.949 1.004-1.236 1.69-2.805 2.057-4.708v-0.772h-5.898v-2.050c1.873-0.042 4.029-0.19 6.467-0.443l-0.342-0.987c-2.751 0.388-5.096 0.57-7.037 0.544v5.67zM15.098 14.204c-0.848-1.050-1.39-2.362-1.626-3.936h3.303c-0.283 1.595-0.842 2.907-1.677 3.936z' />
    </SvgIcon>
  )
}

async function checkCallback() {
  const currentURL = new URLSearchParams(window.location.search)
  const oauthToken = currentURL.get('oauth_token')
  if (!oauthToken) {
    throw new Error('OAuth failed')
  }
  try {
    await AuthAPI.callback(oauthToken)
  } catch (err) {
    throw err
  }
}

const App: React.FC = (props?: any) => {
  const { enqueueSnackbar } = useSnackbar()
  const isCallback = window.location.pathname === '/callback'
  const [loading, setLoading] = useState(isCallback)
  const [willUnmount, setWillUnmount] = useState(false)

  useEffect(() => {
    const cookie = Cookies.get('fftk')
    if (isCallback) {
      checkCallback()
        .then(() => {
          window.setTimeout(() => {
            setWillUnmount(true)
            window.setTimeout(() => {
              props.history.push('/home')
            }, 100)
          }, 1000)
        })
        .catch(err => {
          setLoading(false)
          clearSession()
          window.setTimeout(() => {
            props.history.push('/')
          }, 300)
          enqueueSnackbar(`登录失败\n${err.data.hint}`, { variant: 'error' })
        })
    } else if (cookie) {
      setLoading(true)
      props
        .verifyCredentials()
        .then(() => {
          window.setTimeout(() => {
            setWillUnmount(true)
            window.setTimeout(() => {
              props.history.push('/home')
            }, 100)
          }, 1000)
        })
        .catch(() => {
          setLoading(false)
          clearSession()
          window.setTimeout(() => {
            props.history.push('/')
          }, 300)
        })
    }
  }, [props, isCallback, enqueueSnackbar])

  async function signin() {
    try {
      setLoading(true)
      const res = await AuthAPI.signin()
      const url = new URL(res.data)
      window.setTimeout(() => {
        window.location.href = url.href
      }, 300)
    } catch (err) {
      alert(err)
      setLoading(false)
    }
  }

  const classes = useStyles()

  return (
    <Container component='main' maxWidth='lg' className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Container maxWidth='sm'>
            <Slide in={!willUnmount} direction='down' timeout={{ enter: isCallback ? 0 : 300, exit: 300 }}>
              <Card>
                <CardMedia image={meshiya} title='飯屋' className={classes.media} />

                <CardContent>
                  <Box className={classes.titleBox}>
                    <Typography gutterBottom={true} variant='h5' component='h2'>
                      飯屋
                    </Typography>
                  </Box>
                  <Box className={classes.quoteBox}>
                    <Quote />
                  </Box>
                </CardContent>

                <CardActions className={classes.actions}>
                  <Fab
                    color='primary'
                    size='large'
                    variant='extended'
                    className={classes.loginButton}
                    onClick={signin}
                    disabled={loading}>
                    {!loading && <FanfouIcon className={classes.fanfouIcon} />}
                    {loading && <CircularProgress size={30} className={classes.loadingIcon} />}
                    饭否登录
                  </Fab>
                </CardActions>
              </Card>
            </Slide>
          </Container>
        </div>
      </div>

      <div className={classes.copyright}>© 2019 Mogita</div>
    </Container>
  )
}

export default connect(
  mapState as any,
  mapDispatch as any,
)(App)
