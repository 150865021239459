import { createModel } from '@rematch/core'
import { FollowAPI, UsersAPI } from '../../api'
import { IFollowDiff } from '../../types/Follow'
import IUser from '../../types/User'

export const followDiff = createModel({
  state: {
    loadingForPage: false,
    loadingForItem: false,
    data: {
      exparte_followers: [],
      exparte_followings: [],
    } as IFollowDiff,
  },
  reducers: {
    setData: (state, payload) => ({ ...state, ...payload }),
    setFollowerUser: (state, payload: { user: IUser; index: number }) => {
      const newState = Object.assign({}, state)
      newState.data.exparte_followers[payload.index] = payload.user
      return newState
    },
    setFollowingUser: (state, payload: { user: IUser; index: number }) => {
      const newState = Object.assign({}, state)
      newState.data.exparte_followings[payload.index] = payload.user
      return newState
    },
    setLoadingForPage: (state, payload: boolean) => {
      return {
        ...state,
        loadingForPage: payload,
      }
    },
    setLoadingForItem: (state, payload: boolean) => {
      return {
        ...state,
        loadingForItem: payload,
      }
    },
  },
  effects: dispatch => ({
    async getFollowDiff() {
      try {
        dispatch.followDiff.setLoadingForPage(true)
        // fix: res should be IFollowDiff type
        const res: any = await FollowAPI.followDiff()
        dispatch.followDiff.setData({
          data: {
            exparte_followers: res.exparte_followers.filter((item: IUser) => item.fanfou_id !== ''),
            exparte_followings: res.exparte_followings.filter((item: IUser) => item.fanfou_id !== ''),
          },
        })
      } catch (err) {
        throw err
      } finally {
        dispatch.followDiff.setLoadingForPage(false)
      }
    },
    async clearFollowDiff() {
      dispatch.followDiff.setData({ data: { exparte_followers: [], exparte_followings: [] } })
    },
    async getUserDetail(param: { type: string; id: string; index: number }) {
      try {
        dispatch.followDiff.setLoadingForItem(true)

        const res: any = await UsersAPI.showUser(param.id)
        switch (param.type) {
          case 'exparte_followers':
            dispatch.followDiff.setFollowerUser({ user: res, index: param.index })
            break
          case 'exparte_followings':
            dispatch.followDiff.setFollowingUser({ user: res, index: param.index })
            break
          default:
            return
        }
      } catch (err) {
        throw err
      } finally {
        dispatch.followDiff.setLoadingForItem(false)
      }
    },
  }),
})
