import { Fade, Typography } from '@material-ui/core'
import React from 'react'
import Quotes from '../../assets/quotes.json'

let lastIndex = -1
function getQuote(): string {
  const n = Math.floor(Math.random() * Quotes.length)
  if (n === lastIndex) {
    return getQuote()
  }
  lastIndex = n
  return Quotes[n]
}

class Quote extends React.Component {
  public state = {
    isIn: true,
    quote: getQuote(),
  }

  private timer: number = 0

  public componentDidMount() {
    this.timer = window.setInterval(() => {
      this.setState(
        {
          isIn: false,
        },
        () => {
          window.setTimeout(() => {
            this.setState({
              quote: getQuote(),
              isIn: true,
            })
          }, 500)
        },
      )
    }, 11 * 1000)
  }

  public componentWillUnmount() {
    window.clearInterval(this.timer)
  }

  public render() {
    const { quote, isIn } = this.state

    return (
      <Fade in={isIn}>
        <Typography>{quote}</Typography>
      </Fade>
    )
  }
}

export default Quote
