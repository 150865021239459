import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Zoom,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FollowAPI } from './../../api'
import { RootDispatch } from './../../ducks'
import IUser from './../../types/User'

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectionStatusBox: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  selectionStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  batchButton: {
    marginLeft: theme.spacing(1),
  },
  colID: {
    width: 160,
    minWidth: 160,
    maxWidth: 160,
  },
  colDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    height: 58,
  },
  getAllButton: {
    marginLeft: theme.spacing(1),
  },
  progress: {
    marginLeft: theme.spacing(2),
  },
  detailCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    height: 32,
    width: 32,
    borderRadius: 4,
    marginRight: theme.spacing(2),
  },
}))

interface IDefaultProps {
  type: string
  list: IUser[]
  loading: boolean
}

const CustomTable: React.FC<IDefaultProps> = (props: IDefaultProps) => {
  const { list, loading } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const dispatch = useDispatch<RootDispatch>()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [selected, setSelected] = React.useState<string[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [batchInProgress, setBatchInProgress] = useState(false)

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getLabelDisplayedRows = (arg: { from: number; to: number; count: number }) => `${arg.from}-${arg.to}`

  function getUserDetail(id: string, index: number) {
    dispatch.followDiff.getUserDetail({ type: props.type, id, index: index + page * rowsPerPage })
  }

  function getUserDetailForCurrentPage() {
    list.slice(page * rowsPerPage, (page + 1) * rowsPerPage).forEach((item, index) => {
      if (item.id === 0 && dayjs(item.created_at).unix() <= 0) {
        getUserDetail(item.fanfou_id, index)
      }
    })
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = list.map(item => item.fanfou_id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  function handleSelectClick(event: React.MouseEvent<unknown>, id: string) {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  async function handleBatchClick() {
    if (selected.length <= 0) {
      return
    }

    setBatchInProgress(true)
    const direction = props.type === 'exparte_followings' ? 'unfollow' : 'follow'
    let successCount = 0
    for (const id of selected) {
      try {
        await FollowAPI[direction](id)
        successCount++
      } catch (err) {
        // failure toast here
        enqueueSnackbar(`${selected.length > 0 ? '批量' : ''}操作过程中出错，请稍后重试`, { variant: 'error' })
        break
      }
    }

    setSelected([])
    setDialogOpen(false)
    setBatchInProgress(false)
    if (successCount === selected.length) {
      enqueueSnackbar(`${selected.length > 0 ? '批量' : ''}操作已完成`, { variant: 'success' })
    }
    dispatch.followDiff.getFollowDiff()
  }

  function handleDialogOpen() {
    setDialogOpen(true)
  }

  function handleDialogClose() {
    setDialogOpen(false)
  }

  return (
    <Paper>
      <Box className={classes.box}>
        <div className={classes.selectionStatusBox}>
          <Zoom in={selected.length > 0}>
            <div className={classes.selectionStatus}>
              <Typography color='inherit' variant='subtitle1'>
                已选中 {selected.length} 个用户
              </Typography>

              <Button
                className={classes.batchButton}
                variant='text'
                color='primary'
                size='large'
                onClick={handleDialogOpen}>
                {selected.length > 1 && '批量'}
                {props.type === 'exparte_followings' && '取消'}关注
              </Button>
            </div>
          </Zoom>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage='每页数量'
          labelDisplayedRows={getLabelDisplayedRows}
        />
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < list.length}
                checked={selected.length === list.length}
                inputProps={{ 'aria-label': 'select all IDs' }}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell className={classes.colID}>ID</TableCell>
            <TableCell>
              <div className={classes.colDetail}>
                详细信息
                {loading ? (
                  <CircularProgress size={32} className={classes.progress} />
                ) : (
                  <Button
                    className={classes.getAllButton}
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={getUserDetailForCurrentPage}>
                    获取本页
                  </Button>
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const isItemSelected = isSelected(row.fanfou_id)
            return (
              <TableRow key={index} className={classes.row}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': `checkbox-${index}` }}
                    onClick={event => handleSelectClick(event, row.fanfou_id)}
                  />
                </TableCell>
                <TableCell className={classes.colID} component='th' scope='row'>
                  <Link target='_blank' rel='noopener' href={`https://fanfou.com/${row.fanfou_id}`}>
                    {row.fanfou_id}
                  </Link>
                </TableCell>
                <TableCell className={classes.detailCell}>
                  {row.id > 0 || dayjs(row.created_at).unix() > 0 ? (
                    <>
                      <img
                        className={classes.avatar}
                        src={row.profile_image_url_large}
                        alt={`avatar of ${row.screen_name}`}
                      />
                      <span>
                        {row.screen_name}（注册时间：{dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}，消息数：
                        {row.statuses_count}，关注者：{row.followers_count}，关注：{row.friends_count}）
                      </span>
                    </>
                  ) : (
                    <Button
                      variant='outlined'
                      size='small'
                      color='primary'
                      onClick={() => getUserDetail(row.fanfou_id, index)}>
                      获取详细信息
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{selected.length > 1 && '批量'}操作确认</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            要{props.type === 'exparte_followings' && '取消'}关注所选的 {selected.length} 个用户吗？
            {selected.length > 10 ? (
              <>
                <br />
                本次操作耗时可能较长，在批量操作期间请勿关闭或刷新本页
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{batchInProgress ? '关闭' : '放弃'}</Button>
          <Button onClick={handleBatchClick} color='primary' disabled={batchInProgress}>
            {batchInProgress && <CircularProgress size={16} />}
            {!batchInProgress && <>确认{props.type === 'exparte_followings' && '取消'}关注</>}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default CustomTable
