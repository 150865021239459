import {
  AppBar,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core'
import { AccountCircle, Home as HomeIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import DelayedLink from './../../components/DelayedLink'
import { RootDispatch, RootState } from './../../ducks'
import { clearSession } from './../../utils/session'
import APIRateComp from './api-rate'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    cursor: 'default',
    userSelect: 'none',
  },
  appName: {
    cursor: 'default',
    userSelect: 'none',
  },
  homeButton: {
    position: 'relative',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
    width: 'auto',
  },
  homeButtonLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  apiRate: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  apiRateSeparator: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  apiRateMenuItem: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  apiRateStatusBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    height: 21,
    width: 21,
    borderRadius: 50,
  },
}))

const NavigationBar: React.FC = (props?: any) => {
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch<RootDispatch>()
  const classes = useStyles()
  const { history, match } = useReactRouter()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuEl)
  const [willTransit, setWillTransit] = useState(false)

  useEffect(() => {
    if (!user.id) {
      dispatch.user.verifyCredentials().catch(err => {
        clearSession()
        window.setTimeout(() => {
          history.push('/')
        }, 300)
      })
      dispatch.apiRate.getAPIRate()
    }
  }, [user, dispatch, history])

  const handleSignout = () => {
    dispatch.user.signout({ history })
  }

  const handleAvatarClick = (evt: React.MouseEvent<HTMLElement>) => {
    setMenuEl(evt.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }

  return (
    <AppBar position='sticky' color='default'>
      <Toolbar>
        <Typography variant='h6' noWrap={true} className={classes.title}>
          飯屋
        </Typography>

        <Zoom in={match.path !== '/home' && !willTransit}>
          <div
            className={classes.homeButton}
            onClick={() => {
              setWillTransit(true)
            }}>
            <DelayedLink to='/home' className={classes.homeButtonLink}>
              <Tooltip title='返回主屏'>
                <IconButton>
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            </DelayedLink>
          </div>
        </Zoom>

        <Zoom in={match.path !== '/home' && !willTransit}>
          <Tooltip title={props.app.description || ''}>
            <Typography className={classes.appName}>{props.app.name}</Typography>
          </Tooltip>
        </Zoom>

        <div className={classes.grow} />

        <Tooltip title={user.screen_name ? user.screen_name : ''}>
          <IconButton
            aria-label='account of current user'
            aria-controls='menu-appbar-avatar'
            aria-haspopup='true'
            color='inherit'
            onClick={handleAvatarClick}>
            {user.profile_image_url_large ? (
              <img src={user.profile_image_url_large} alt='avatar' className={classes.avatar} />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
        </Tooltip>

        <Menu
          id='menu-appbar-avatar'
          PaperProps={{
            style: {
              minWidth: 120,
            },
          }}
          anchorEl={menuEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted={true}
          open={menuOpen}
          onClose={handleMenuClose}>
          <MenuItem component={APIRateComp} />
          <Divider />
          <MenuItem onClick={handleSignout} dense={true}>
            登出
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default NavigationBar
