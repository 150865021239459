import { createModel } from '@rematch/core'
import { AuthAPI } from '../../api'
import User from './../../types/User'
import { clearSession } from './../../utils/session'

export const user = createModel({
  state: {} as User,
  reducers: {
    setData: (state, payload) => ({ ...state, ...payload }),
  },
  effects: dispatch => ({
    async verifyCredentials() {
      try {
        const res = await AuthAPI.verifyAuth()
        dispatch.user.setData(res)
      } catch (err) {
        throw err
      }
    },
    async signout(payload?: any) {
      try {
        dispatch.user.setData({})
        await AuthAPI.signout()
      } catch (err) {
        throw err
      } finally {
        clearSession()
        window.setTimeout(() => {
          payload.history.push('/')
        }, 300)
      }
    },
  }),
})
