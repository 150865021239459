import axios from 'axios'
import axiosCookieJar from 'axios-cookiejar-support'
import tough from 'tough-cookie'

axiosCookieJar(axios)
const cookieJar = new tough.CookieJar()

const API = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 15 * 1000,
  withCredentials: true,
  jar: cookieJar,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
})

API.interceptors.response.use(resp => resp.data, err => Promise.reject(err.response || `服务器无法处理此次请求`))

export default API

export { AuthAPI } from './endpoints/auth'
export { AccountAPI } from './endpoints/account'
export { FollowAPI } from './endpoints/follow'
export { UsersAPI } from './endpoints/users'
