import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Cached as CachedIcon } from '@material-ui/icons'
import dayjs from 'dayjs'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch, RootState } from './../../ducks'

const useStyles = makeStyles(theme => ({
  apiRate: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  apiRateSeparator: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  apiRateMenuItem: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  apiRateStatusBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const APIRateComp: React.FC = React.forwardRef((props?: any, ref?: any) => {
  const classes = useStyles()
  const apiRate = useSelector((state: RootState) => state.apiRate)
  const dispatch = useDispatch<RootDispatch>()

  function handleClickRefresh() {
    dispatch.apiRate.getAPIRate()
  }

  return (
    <div ref={ref} className={classes.apiRateMenuItem}>
      <Box className={classes.apiRateStatusBox}>
        <div className={classes.apiRate}>
          <Typography variant='body2'>API 调用：</Typography>
          <Typography variant='body2'>{apiRate.remaining_hits || '-'}</Typography>

          <Typography variant='body2' className={classes.apiRateSeparator}>
            /
          </Typography>

          <Typography variant='body2'>{apiRate.hourly_limit || '-'}</Typography>
        </div>
        <Typography variant='caption'>
          {'下次重置时间：' + (apiRate.reset_time ? dayjs(apiRate.reset_time).format('HH:mm:ss') : '-')}
        </Typography>
      </Box>

      <IconButton onClick={handleClickRefresh} style={{ alignSelf: 'center' }} aria-label='refresh api rate'>
        <CachedIcon fontSize='small' />
      </IconButton>
    </div>
  )
})

export default APIRateComp
