import React from 'react'
import { RouteComponentProps } from 'react-router'
import NoodlesImage from './../../assets/images/noodles.jpg'
import withTopNav from './../../components/NavigationBar/hoc'
import Analyze from './../../pages/Analyze'
import Auth from './../../pages/Auth'
import Home from './../../pages/Home'

interface IRouteItem {
  path: string | string[]
  component: React.ComponentType<RouteComponentProps<any>>
  exact?: boolean
  hidden?: boolean
  name?: string
  description?: string
  image?: string
}

const AppRegistry: IRouteItem[] = [
  {
    path: ['/', '/callback'],
    exact: true,
    component: props => <Auth {...props} />,
    hidden: true,
  },
  {
    name: 'Home',
    path: '/home',
    component: props => {
      const Comp = withTopNav(Home)
      return <Comp {...props} />
    },
    hidden: true,
  },
  {
    name: '关注分析',
    path: '/follow-analyze',
    component: props => {
      const Comp = withTopNav(Analyze)
      return <Comp {...props} />
    },
    description: '查询关注统计，批量关注和取消关注',
    image: NoodlesImage,
  },
]

export default AppRegistry
