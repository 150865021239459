import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Apps from './components/AppRegistry'

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {Apps.map((app, index) => (
          <Route
            key={index}
            path={app.path}
            exact={Boolean(app.exact)}
            // tslint:disable-next-line: jsx-no-lambda
            component={(props: any) => <app.component {...props} app={app} />}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
