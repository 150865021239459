import * as Sentry from '@sentry/browser'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './ducks'
import './index.css'
import { register as registerServiceWorker } from './serviceWorker'

declare const module: any

Sentry.init({ dsn: 'https://3c4f4425fa944e8a9ab65c2e7e58c2e4@sentry.io/1540663' })
ReactGA.initialize('UA-147386796-1')

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <App />
      </SnackbarProvider>
    </Provider>,
    document.getElementById('root'),
  )
}

renderApp()

if (module.hot) {
  module.hot.accept('./App', renderApp)
}

registerServiceWorker()
