import { Button, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { Fragment } from 'react'

const useStyles = makeStyles(theme => ({
  button: {
    color: '#ffffff',
  },
}))

const UpdateAlert: React.FC = () => {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  let swEvent: CustomEvent

  window.addEventListener('fftk-new-version', ((evt: CustomEvent) => {
    if (!evt || !evt.detail || typeof evt.detail.postMessage !== 'function') {
      return
    }

    swEvent = evt

    enqueueSnackbar(
      <div>
        <strong>飯屋已更新</strong>
        <br />
        新版将在重新载入页面后生效，
        <br />
        是否要现在重新载入页面？
      </div>,
      {
        action,
        persist: true,
        variant: 'info',
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    )
  }) as EventListener)

  const action = (key: any) => (
    <Fragment>
      <Button
        className={classes.button}
        onClick={() => {
          swEvent.detail.postMessage({ action: 'skipWaiting' })
          window.location.reload()
        }}>
        {'立即重新载入'}
      </Button>
      <Button
        className={classes.button}
        onClick={() => {
          closeSnackbar(key)
        }}>
        {'暂不'}
      </Button>
    </Fragment>
  )

  return null
}

export default UpdateAlert
