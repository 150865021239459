import { createMuiTheme } from '@material-ui/core'
// tslint:disable-next-line: no-submodule-imports
import { blue, red } from '@material-ui/core/colors'

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: blue[500],
    },
    error: {
      main: red.A400,
    },
  },
})

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blue[500],
    },
    secondary: {
      main: blue[500],
    },
    error: {
      main: red.A400,
    },
  },
})

export { lightTheme, darkTheme }
