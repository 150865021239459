import { createModel } from '@rematch/core'
import { AccountAPI } from '../../api'
import { IAPIRate } from '../../types/Account'

export const apiRate = createModel({
  state: {} as IAPIRate,
  reducers: {
    setData: (state, payload) => ({ ...state, ...payload }),
  },
  effects: dispatch => ({
    async getAPIRate() {
      try {
        const res = await AccountAPI.apiRateStatus()
        dispatch.apiRate.setData(res)
      } catch (err) {
        throw err
      }
    },
  }),
})
